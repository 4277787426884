<template>
	<div>
		<DaModalFrame v-model:open="modalIsOpen">
			<DeleteConfirm
				:data="data"
				@close="modalIsOpen = false"
				@submit="onDelete"
			/>
		</DaModalFrame>
		<div class="relative mb-3">
			<div class="relative flex justify-between items-center">
				<div class="font-semibold text-2xl text-neutral-700">
					<span v-if="index || index === '0'">
						{{ Number(index) + 1 }}.</span
					>
					{{ data.name }}
				</div>
				<div class="bg-body pl-3 flex items-center gap-2">
					<button
						class="
							cursor-pointer
							button-p
							text-center
							bg-none
							shadow-none
							flex
							items-center
							justify-center
							btn--text
							w-fit
						"
						type="button"
						@click="expend = !expend"
					>
						<div
							:class="{
								'rotate-180': !expend,
							}"
							class="mr-1 transform"
						>
							<svg
								fill="none"
								height="20"
								viewBox="0 0 20 20"
								width="20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M15 12.5L10 7.5L5 12.5"
									stroke="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</div>
						<span>{{ !expend ? 'Mở rộng' : 'Thu gọn' }}</span>
					</button>
					<Menu
						v-if="data?.id !== 'no_have_group'"
						as="div"
						class="ml-3 relative"
					>
						<div>
							<MenuButton
								class="
									rounded-full
									flex
									text-sm
									focus:outline-none
									focus:ring-2
									focus:ring-offset-2
									focus:ring-indigo-500
								"
							>
								<span class="sr-only">Open user menu</span>
								<svg
									fill="none"
									height="20"
									viewBox="0 0 20 20"
									width="20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										clip-rule="evenodd"
										d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
										fill="#94A3B8"
										fill-rule="evenodd"
									/>
								</svg>
							</MenuButton>
						</div>
						<transition
							enter-active-class="transition ease-out duration-200"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95"
						>
							<MenuItems
								class="
									origin-top-right
									absolute
									right-0
									mt-2
									w-60
									rounded-md
									py-1
									bg-white
									focus:outline-none
									z-10
									shadow-sm
									border border-yellow-100
								"
							>
								<MenuItem
									v-slot="{ active }"
									@click="
										$router.push(
											isGroupClass
												? `/create-group-class/${
														data.plan_id || 0
												  }?group_class=${id}&group_id=${
														data.id
												  }&type=${type}`
												: `/create-group/${
														data.plan_id || 0
												  }?student_id=${id}&group_id=${
														data.id
												  }&type=${type}`
										)
									"
								>
									<div
										:class="active ? '' : ''"
										class="
											p-2
											px-4
											bg-white
											z-10
											text-gray-600
											hover:bg-neutral-50
											flex
											items-center
											gap-4
											cursor-pointer
										"
									>
										<svg
											fill="none"
											height="14"
											viewBox="0 0 14 14"
											width="14"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												clip-rule="evenodd"
												d="M11.47 5.6509L12.71 4.41087C12.8459 4.27501 12.9138 4.20708 12.9664 4.14512C13.6006 3.39844 13.6006 2.30226 12.9664 1.55558C12.9138 1.49362 12.8459 1.42569 12.71 1.28983C12.5741 1.15397 12.5062 1.08604 12.4442 1.03341C11.6976 0.399182 10.6014 0.399182 9.85471 1.03341C9.79276 1.08603 9.72485 1.15394 9.58905 1.28973L9.58898 1.28981L9.58896 1.28983L8.33075 2.54804C9.07441 3.84224 10.1581 4.91751 11.47 5.6509ZM6.87608 4.0027L1.86248 9.0163C1.43742 9.44136 1.22489 9.65389 1.08516 9.91499C0.945425 10.1761 0.88648 10.4708 0.768589 11.0603L0.372934 13.0385C0.306412 13.3712 0.273151 13.5375 0.367759 13.6321C0.462367 13.7267 0.628672 13.6934 0.961282 13.6269L2.93956 13.2312L2.93958 13.2312L2.9396 13.2312C3.52902 13.1133 3.82374 13.0544 4.08483 12.9147C4.34592 12.7749 4.55845 12.5624 4.98349 12.1374L4.98352 12.1373L4.98352 12.1373L10.0115 7.10934C8.75086 6.31113 7.68177 5.24925 6.87608 4.0027Z"
												fill="#94A3B8"
												fill-rule="evenodd"
											/>
										</svg>
										<span class="mt-0.5">Chỉnh sửa</span>
									</div>
								</MenuItem>
								<MenuItem
									v-slot="{ active }"
									@click="modalIsOpen = !modalIsOpen"
								>
									<div
										:class="active ? '' : ''"
										class="
											p-2
											px-4
											bg-white
											z-10
											text-gray-600
											hover:bg-neutral-50
											flex
											items-center
											gap-4
											cursor-pointer
										"
									>
										<svg
											fill="none"
											height="16"
											viewBox="0 0 16 16"
											width="16"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												clip-rule="evenodd"
												d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
												fill="#94A3B8"
												fill-rule="evenodd"
											/>
										</svg>

										<span class="mt-0.5"
											>Xoá nhóm mục tiêu</span
										>
									</div>
								</MenuItem>
							</MenuItems>
						</transition>
					</Menu>
					<div v-else class="w-5 h-5"></div>
				</div>
			</div>
			<div
				v-if="data.inserted_at"
				class="uppercase text-green-500 font-semibold text-sm mt-2"
			>
				tạo bởi
				<span> {{ data.created_by_staff?.name || '-' }}</span>
				<span>
					|
					<span v-if="data?.inserted_at">{{
						$filters.formatDateTime(data?.inserted_at)
					}}</span>
				</span>
			</div>
		</div>
		<div class="mt-6">
			<div class="flex flex-nowrap w-full md-down:flex-col gap-4">
				<div
					class="
						bg-white
						px-6
						py-4
						border
						rounded-xl
						shadow
						w-full
						flex
						items-center
						justify-between
					"
				>
					<div class="flex items-center gap-2">
						<img
							alt=""
							class="mr-2"
							height="32"
							src="@/assets/images/icons/abc.png"
							style="height: 32px"
							width="32"
						/>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số lĩnh vực
							</span>
						</div>
					</div>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							flex flex-nowrap
							items-center
						"
					>
						{{ data.skills_count }}
					</div>
				</div>
				<div
					class="
						bg-white
						px-6
						py-4
						border
						rounded-xl
						shadow
						w-full
						flex
						items-center
						justify-between
					"
				>
					<div class="flex items-center gap-2">
						<img
							alt=""
							class="mr-2"
							height="32"
							src="@/assets/images/icons/light.png"
							style="height: 32px"
							width="32"
						/>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu
							</span>
						</div>
					</div>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							flex flex-nowrap
							items-center
						"
					>
						{{ data.goals_count }}
					</div>
				</div>
			</div>
		</div>
		<div v-if="expend" class="mt-6">
			<Loading v-if="isLoading" />
			<div v-else>
				<div v-if="data?.goals_group_by_skill?.length">
					<div>
						<div class="mb-10">
							<div
								class="shadow-sm sm:rounded-2xl overflow-hidden"
							>
								<div
									class="
										__record__table
										overflow-x-auto
										scroll-bar
									"
								>
									<div
										class="
											overflow-hidden
											sm:rounded-t-2xl
											w-fit
											min-w-full
										"
									>
										<table
											class="
												min-w-full
												divide-y
												text-neutral-200
											"
										>
											<thead class="bg-neutral-100 h-16">
												<tr>
													<th
														class="
															px-6
															py-3
															text-left text-xs
															font-semibold
															text-neutral-600
															uppercase
															tracking-wider
															w-16
															whitespace-nowrap
														"
														scope="col"
													>
														STT
													</th>
													<th
														class="
															px-6
															py-3
															text-left text-xs
															font-semibold
															text-neutral-600
															uppercase
															tracking-wider
															w-16
															whitespace-nowrap
														"
														scope="col"
														@click="sortName"
													>
														<div
															class="
																flex
																items-center
																gap-1
																justify-between
																cursor-pointer
															"
														>
															LĨNH VỰC
															<svg
																fill="none"
																height="16"
																viewBox="0 0 16 16"
																width="16"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	clip-rule="evenodd"
																	d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
																	fill="#94A3B8"
																	fill-rule="evenodd"
																/>
															</svg>
														</div>
													</th>
													<th
														class="
															px-6
															py-3
															text-left text-xs
															font-semibold
															text-neutral-600
															uppercase
															tracking-wider
															whitespace-nowrap
														"
														scope="col"
														style="min-width: 160px"
													>
														Nội dung
													</th>
												</tr>
											</thead>
											<tbody
												v-for="(
													goal, index
												) in data?.goals_group_by_skill"
												:key="index"
												style="border-top: none"
											>
												<tr
													v-for="(
														g, subIndex
													) in isGroupClass
														? goal.group_goals
														: goal.goals"
													:key="g.goal_id || g.id"
													:class="
														getIndex(
															index,
															subIndex
														) %
															2 ===
														0
															? 'bg-white'
															: 'bg-neutral-50'
													"
													class="
														text-neutral-700
														hover:bg-orange-50
														cursor-pointer
													"
													@click.stop="
														$router.push(
															`/${
																isGroupClass
																	? 'group-class'
																	: 'goal'
															}/${id}/${
																g.goal_id ||
																g.id
															}`
														)
													"
												>
													<td
														class="
															px-6
															py-4
															whitespace-nowrap
															text-neutral-700
														"
													>
														<div class="ml-2">
															{{
																getIndex(
																	index,
																	subIndex
																)
															}}
														</div>
													</td>
													<td
														class="
															px-6
															py-4
															whitespace-nowrap
															text-neutral-700
														"
													>
														<div class="ml-2">
															{{ g.skill.name }}
														</div>
													</td>
													<td
														class="
															px-6
															py-4
															text-neutral-700
														"
													>
														<div
															class="
																flex
																items-center
															"
														>
															<div
																class="
																	mr-2
																	hover:text-yellow-700
																	hover:underline
																"
																v-html="
																	g.goal_name ||
																	g.name
																"
															></div>
															<TooltipGoalDetail
																:goal-id="
																	g.goal_id ||
																	g.id
																"
																:is-group="
																	isGroupClass
																"
																:student-id="id"
															/>
														</div>
														<div
															class="
																flex
																gap-2
																flex-wrap
															"
															style="
																max-width: 360px;
															"
														>
															<!-- <div
																v-if="
																	g?.raw_source
																"
																class="
																	mt-1
																	py-0.5
																	px-2.5
																	rounded-full
																	bg-neutral-200
																	text-neutral-700
																	h-fit
																	w-fit
																	font-semibold
																	shadow-input
																	whitespace-nowrap
																"
																style="
																	font-size: 10px;
																"
															>
																{{
																	g?.display_source
																}}
															</div> -->
															<div
																v-if="g?.source"
																class="
																	mt-1
																	py-0.5
																	px-2.5
																	rounded-full
																	bg-neutral-200
																	text-neutral-700
																	h-fit
																	w-fit
																	font-semibold
																	shadow-input
																	whitespace-nowrap
																"
																style="
																	font-size: 10px;
																"
															>
																{{ g?.source }}
															</div>
															<div
																v-else
																class="
																	mt-1
																	py-0.5
																	px-2.5
																	rounded-full
																	bg-neutral-200
																	text-neutral-700
																	h-fit
																	w-fit
																	font-semibold
																	shadow-input
																	whitespace-nowrap
																"
																style="
																	font-size: 10px;
																"
															>
																{{
																	$filters.sourceMap(
																		g?.state,
																		g.editable
																	)
																}}
															</div>
															<template
																v-if="
																	g?.assistance_levels &&
																	org.enable_goal_assistance_level
																"
																class="
																	flex
																	gap-2
																"
															>
																<div
																	v-for="ass in g?.assistance_levels"
																	:key="ass"
																>
																	<div
																		class="
																			mt-1
																			py-0.5
																			px-2.5
																			rounded-full
																			bg-pink-100
																			whitespace-nowrap
																			text-neutral-700
																			h-fit
																			w-fit
																			font-semibold
																			shadow-input
																		"
																		style="
																			font-size: 10px;
																		"
																	>
																		{{
																			ass
																		}}
																	</div>
																</div>
															</template>
															<div
																class="
																	mt-1
																	py-0.5
																	px-2.5
																	rounded-full
																	bg-yellow-tag
																	text-neutral-700
																	h-fit
																	w-fit
																	font-semibold
																	shadow-input
																	whitespace-nowrap
																"
																style="
																	font-size: 10px;
																"
																v-if="g?.age"
															>
																{{ g?.age }}
																tháng tuổi
															</div>
														</div>
														<div
															class="
																flex
																items-center
																gap-2
																mt-2
															"
														>
															<div
																v-if="
																	g?.has_media
																"
															>
																<img
																	alt=""
																	src="@/assets/images/icons/has_media.png"
																/>
															</div>
															<div
																v-if="
																	g.number_of_assessment >
																	0
																"
																class="
																	text-neutral-400
																	text-xs
																	font-semibold
																"
															>
																ĐÃ HỌC
																{{
																	g.number_of_assessment
																}}
																NGÀY
															</div>
															<div
																v-if="
																	g?.completed_at
																"
															>
																<img
																	alt=""
																	src="@/assets/images/icons/has_taught.png"
																/>
															</div>
														</div>
														<div
															v-if="
																g.complete_percent >
																0
															"
															class="
																flex
																items-center
																gap-2
																mt-2
															"
														>
															<div
																class="
																	text-neutral-400
																	text-xs
																	font-semibold
																"
															>
																KẾT QUẢ
															</div>
															<div
																class="
																	progress-bar
																	overflow-hidden
																"
															>
																<div
																	:style="{
																		background:
																			$filters.tagResult(
																				g.complete_percent
																			)
																				?.background,
																		width:
																			g.complete_percent +
																			'%',
																	}"
																	class="
																		h-full
																	"
																></div>
															</div>
															<div
																class="
																	text-neutral-400
																	text-xs
																	font-semibold
																"
															>
																{{
																	g.complete_percent
																}}%
															</div>
														</div>
														<div
															v-if="
																g.parent
																	?.sub_goal_count >
																0
															"
															class="
																goal-block
																flex
																gap-3
																mt-2
															"
														>
															<img
																class="
																	w-3
																	h-3
																	mt-1
																"
																src="@/assets/images/icons/goal-arrow.png"
																alt=""
															/>
															<div>
																<div
																	class="
																		whitespace-nowrap
																		text-neutral-700
																	"
																>
																	{{
																		g.parent
																			?.name
																	}}
																</div>
																<div
																	class="
																		text-neutral-400
																		text-xs
																		font-semibold
																	"
																>
																	{{
																		g.parent
																			?.sub_goal_count
																	}}
																	MỤC TIÊU
																</div>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import Loading from '@/components/common/Loading';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import DeleteConfirm from '@/components/modules/groups/DeleteConfirm';

export default {
	name: 'GroupDetail',

	components: {
		DeleteConfirm,
		DaModalFrame,
		Loading,
		TooltipGoalDetail,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
	},

	props: {
		index: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		type: {
			type: String,
			default: '',
		},
		isGroupClass: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			expend: true,
			isLoading: false,
			modalIsOpen: false,
			sum: 0,
			i: -1,
			sort: '',
		};
	},
	computed: {
		id() {
			return this.$route.params?.id;
		},

		org() {
			return this.$store.state.profile.current_org;
		},
	},

	methods: {
		getIndex(index, subIndex) {
			let previousLength = 0;
			for (let i = 0; i < index; i++) {
				previousLength += this.isGroupClass
					? this.data.goals_group_by_skill[i].group_goals.length
					: this.data.goals_group_by_skill[i].goals.length;
			}
			return previousLength + subIndex + 1;
		},
		sortName() {
			this.sort =
				this.sort == 'skill_name_asc'
					? 'skill_name_desc'
					: 'skill_name_asc';
			this.$emit('sortList', this.sort);
		},
		async onDelete() {
			try {
				this.isGroupClass
					? await $api.groupClasses.deleteGroup(this.id, this.data.id)
					: await $api.goal.deleteGroup(this.id, this.data.id);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Đã xóa thành công nhóm mục tiêu',
				});
				this.modalIsOpen = false;
				this.$emit('refresh');
			} catch (err) {
				await this.$store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>
<style scoped>
.progress-bar {
	width: 203px;
	height: 6px;
	border-radius: 40px;
	background: #cbd5e1;
}
.goal-block {
	background: #eef3f8;
	padding: 12px;
	border-radius: 8px;
	border: 1px solid #bacde4;
}
</style>

<script setup>
import { defineProps, computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';

const props = defineProps(['data', 'id']);

const emit = defineEmits(['edit', 'delete']);

const store = useStore();

const org = computed(() => store.state.profile.current_org);

const goal_added = computed(() => store.getters.goalAdded);
</script>

<template>
	<table class="min-w-full divide-y text-neutral-200">
		<thead class="bg-neutral-100 h-16">
			<tr></tr>
			<tr>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-8
						whitespace-nowrap
					"
				></th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-16
						whitespace-nowrap
					"
				>
					STT
				</th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
					style="min-width: 160px"
				>
					Nội dung
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="(g, i) in props.data"
				:key="g.id"
				class="text-neutral-700"
			>
				<td class="px-6 py-4 w-8 divTableCell">
					<div class="w-4">
						<img
							class="w-full"
							src="@/assets/images/icons/child-goal.png"
							alt=""
						/>
					</div>
				</td>
				<td class="px-6 py-4 whitespace-nowrap text-neutral-700">
					<div class="ml-2">
						{{ i + 1 }}
					</div>
				</td>
				<td class="px-6 py-4 text-neutral-700 w-full">
					<div class="flex items-center gap-1">
						<router-link :to="`/goal/${props.id}/${g.id}`">
							<div class="hover:text-yellow-700 hover:underline">
								{{ g.goal_name || g.name }}
							</div>
						</router-link>
						<TooltipGoalDetail
							:goal-id="g.goal_id || g.id"
							:student-id="props.id"
						/>
					</div>
					<div
						class="flex gap-2 flex-wrap"
						style="max-width: 360px"
						v-if="
							(g?.assistance_levels &&
								org.enable_goal_assistance_level) ||
							g?.raw_source
						"
					>
						<div
							class="
								mt-1
								py-0.5
								px-2.5
								rounded-full
								bg-neutral-200
								text-neutral-700
								h-fit
								w-fit
								whitespace-nowrap
								font-semibold
								shadow-input
							"
							style="font-size: 10px"
							v-if="g?.raw_source"
						>
							{{ g?.display_source }}
						</div>
						<template
							v-if="
								g?.assistance_levels &&
								org.enable_goal_assistance_level
							"
							class="flex gap-2"
						>
							<div v-for="ass in g?.assistance_levels" :key="ass">
								<div
									class="
										mt-1
										py-0.5
										px-2.5
										rounded-full
										bg-pink-100
										whitespace-nowrap
										text-neutral-700
										h-fit
										w-fit
										font-semibold
										shadow-input
									"
									style="font-size: 10px"
								>
									{{ ass }}
								</div>
							</div>
						</template>
						<div
							class="
								mt-1
								py-0.5
								px-2.5
								rounded-full
								bg-yellow-tag
								text-neutral-700
								h-fit
								w-fit
								font-semibold
								shadow-input
								whitespace-nowrap
							"
							style="font-size: 10px"
							v-if="g?.age"
						>
							{{ g?.age }} tháng tuổi
						</div>
					</div>
					<div class="mt-6">
						<div
							class="
								flex
								items-center
								justify-end
								md-down:flex-col md-down:items-end md-down:gap-4
							"
						>
							<div
								class="
									cursor-pointer
									text-yellow-600
									hover:text-yellow-700
									font-semibold
									text-sm
									flex
									items-center
									md-down:items-right
									capitalize
								"
								@click="emit('delete', g)"
							>
								<svg
									class="mr-2"
									fill="none"
									height="16"
									viewBox="0 0 16 16"
									width="16"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										clip-rule="evenodd"
										d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
										fill="currentColor"
										fill-rule="evenodd"
									/>
								</svg>
								XOÁ
							</div>
							<div
								class="
									cursor-pointer
									text-yellow-600
									hover:text-yellow-700
									font-semibold
									text-sm
									flex
									items-center
									md-down:items-right
									whitespace-nowrap
									ml-6
									capitalize
								"
								@click="emit('edit', g)"
							>
								<svg
									class="mr-2"
									fill="none"
									height="16"
									viewBox="0 0 16 16"
									width="16"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										clip-rule="evenodd"
										d="M12.4702 6.65087L13.7102 5.41084C13.8461 5.27498 13.914 5.20705 13.9667 5.14509C14.6009 4.39841 14.6009 3.30223 13.9667 2.55555C13.914 2.49359 13.8461 2.42566 13.7102 2.2898C13.5744 2.15394 13.5064 2.08601 13.4445 2.03338C12.6978 1.39915 11.6016 1.39915 10.855 2.03338C10.793 2.086 10.7251 2.15391 10.5893 2.2897L10.5892 2.28978L10.5892 2.2898L9.33099 3.54801C10.0747 4.84221 11.1583 5.91748 12.4702 6.65087ZM7.87633 5.00267L2.86273 10.0163C2.43767 10.4413 2.22514 10.6539 2.0854 10.915C1.94567 11.1761 1.88672 11.4708 1.76883 12.0602L1.37318 14.0385C1.30666 14.3711 1.27339 14.5374 1.368 14.632C1.46261 14.7266 1.62892 14.6934 1.96153 14.6269L3.9398 14.2312L3.93982 14.2312L3.93984 14.2312C4.52927 14.1133 4.82399 14.0544 5.08508 13.9146C5.34617 13.7749 5.55869 13.5624 5.98373 13.1373L5.98376 13.1373L5.98377 13.1373L11.0118 8.10931C9.7511 7.3111 8.68202 6.24922 7.87633 5.00267Z"
										fill="#E5A42B"
										fill-rule="evenodd"
									/>
								</svg>
								CHỈNH SỬA
							</div>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<template>
	<div class="w-full">
		<div v-if="alertShow">
			<div
				class="bg-yellow-50 border-l-4 border-yellow-600 p-4 rounded-md"
			>
				<div class="relative">
					<div>
						<img
							alt="luca-logo"
							height="24"
							src="@/assets/images/logo/logo.svg"
							width="35"
						/>
					</div>
					<div class="mt-3 text-neutral-600 text-sm">
						Bạn hãy chọn lĩnh vực, điền mục tiêu cho học sinh và ấn
						thêm “thêm mục tiêu” để tạo mục tiêu mới nhé
					</div>
					<div
						class="top-1 right-2 absolute cursor-pointer"
						@click="alertShow = false"
					>
						<svg
							fill="none"
							height="12"
							viewBox="0 0 12 12"
							width="12"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
								fill="#E5A42B"
								fill-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
		<div class="font-semibold text-neutral-500 mb-2 mt-10">Lĩnh vực</div>
		<div>
			<div class="select-tabs mb-6">
				<Multiselect
					v-if="skills.length > 0"
					v-model="skillSelected"
					:allow-empty="false"
					:options="
						skills?.map((s) => {
							return {
								nameSearch: toEnglish(s?.name),
								...s,
							};
						})
					"
					:searchable="true"
					deselect-label="Đã chọn"
					label="nameSearch"
					placeholder="Chọn lĩnh vực (*)"
					selectLabel=""
					track-by="name"
				>
					<template #noResult> Không tìm thấy kết quả</template>
					<template v-slot:option="props">
						<div class="flex flex-nowrap items-center">
							<div>
								{{ props.option.name }}
							</div>
						</div>
					</template>
					<template v-slot:singleLabel="{ option }">
						<div class="flex flex-nowrap items-center font-medium">
							<div>{{ option.name }}</div>
						</div>
					</template>
				</Multiselect>
			</div>
			<div class="font-semibold text-neutral-500 mb-2">Nội dung</div>
			<div class="mb-4">
				<textarea
					v-model="goalContentInput"
					:class="errorName ? 'text-error' : ''"
					placeholder="Nội dung mục tiêu"
				>
				</textarea>
				<div v-if="errorName" class="text-sienna-600 text-sm">
					{{ errorName }}
				</div>
			</div>
			<div
				v-if="enable_goal_assistance_level"
				class="font-semibold text-neutral-500 mb-2"
			>
				Mức độ hỗ trợ
			</div>
			<div
				v-if="enable_goal_assistance_level"
				class="select-tabs mb-6 w-full mr-8"
			>
				<div
					v-for="(item, ass) in assistanceSelecteds"
					:key="ass"
					class="mb-4"
				>
					<Multiselect
						v-if="assistance_levels.length > 0"
						v-model="assistanceSelecteds[ass]"
						:class="errorAssistanceLevels ? 'text-error' : ''"
						:options="
							assistance_levels
								?.filter(
									(a) =>
										!assistanceSelecteds.find(
											(s) => s.order === a.order
										)
								)
								?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
						"
						:searchable="true"
						deselectLabel="Ấn đễ bỏ chọn"
						label="nameSearch"
						placeholder="Chọn mức độ hỗ trợ"
						selectLabel=""
						selectedLabel=""
						track-by="name"
					>
						<template #noResult> Không tìm thấy kết quả</template>
						<template v-slot:option="props">
							<div class="flex flex-nowrap items-center">
								<div>
									{{ props.option.name }}
								</div>
							</div>
						</template>
						<template v-slot:singleLabel="{ option }">
							<div
								class="
									flex flex-nowrap
									items-center
									font-medium
								"
							>
								<div>{{ option.name }}</div>
							</div>
						</template>
					</Multiselect>
					<div
						v-if="errorAssistanceLevels[ass]"
						class="text-sienna-600 text-sm mt-2"
					>
						{{ errorAssistanceLevels[ass] }}
					</div>
				</div>
				<div
					class="
						font-semibold
						text-yellow-700
						mb-2
						text-sm text-right
						cursor-pointer
					"
					@click="addAssistanceLevel"
				>
					Thêm Mức độ hỗ trợ
				</div>
			</div>
			<div
				v-if="enable_goal_reason"
				class="font-semibold text-neutral-500 mb-2"
			>
				Cơ sở hợp lý
			</div>
			<div v-if="enable_goal_reason" class="mb-4">
				<textarea
					v-model="reasonInput"
					:class="errorReason ? 'text-error' : ''"
					placeholder="cơ sở hợp lý của mục tiêu"
				>
				</textarea>
				<div v-if="errorReason" class="text-sienna-600 text-sm">
					{{ errorReason }}
				</div>
			</div>
			<div
				v-if="enable_goal_strategy"
				class="font-semibold text-neutral-500 mb-2"
			>
				Chiến lược
			</div>
			<div v-if="enable_goal_strategy" class="mb-4">
				<textarea
					v-model="strategyInput"
					:class="errorStrategy ? 'text-error' : ''"
					placeholder="Chiến lược"
				>
				</textarea>
				<div v-if="errorStrategy" class="text-sienna-600 text-sm">
					{{ errorStrategy }}
				</div>
			</div>
			<div
				v-if="enable_goal_process"
				class="font-semibold text-neutral-500 mb-2"
			>
				Quy trình thực hiện
			</div>
			<div v-if="enable_goal_process" class="mb-4">
				<textarea
					v-model="processInput"
					:class="errorProcess ? 'text-error' : ''"
					placeholder="Quy trình thực hiện"
				>
				</textarea>
				<div v-if="errorProcess" class="text-sienna-600 text-sm">
					{{ errorProcess }}
				</div>
			</div>
			<div>
				<div
					v-for="(activity, index) in activities"
					:key="index"
					class="mt-6"
				>
					<div class="flex items-center justify-between">
						<div class="font-semibold text-neutral-500 mb-2">
							Hoạt động {{ index + 1 }}
						</div>
						<div
							v-if="activities.length > 1"
							class="
								flex
								items-center
								text-yellow-600
								hover:text-yellow-700
								font-semibold
								cursor-pointer
								text-sm
							"
							@click="removeActivity(index)"
						>
							<svg
								class="mr-2"
								fill="none"
								height="16"
								viewBox="0 0 16 16"
								width="16"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
									fill="currentColor"
									fill-rule="evenodd"
								/>
							</svg>
							<span class="mt-0.5">XOÁ</span>
						</div>
					</div>
					<textarea
						v-model="activity.name"
						:class="errorActivities[index] ? 'text-error' : ''"
						placeholder="Tên hoạt động"
					>
					</textarea>
					<div
						v-if="errorActivities[index]"
						class="text-sienna-600 text-sm"
					>
						{{ errorActivities[index] }}
					</div>
				</div>
				<div class="flex items-center justify-center mt-12">
					<button
						class="
							button-p
							text-center
							bg-none
							shadow-none
							flex
							items-center
							justify-center
							btn--text
							w-fit
						"
						type="button"
						@click="addActivity"
					>
						<svg
							class="mr-2"
							fill="none"
							height="16"
							viewBox="0 0 16 16"
							width="16"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
								fill="#E5A42B"
								fill-rule="evenodd"
							/>
						</svg>
						<span class="mt-1">Thêm hoạt động</span>
					</button>
				</div>
			</div>
			<div class="flex items-center justify-center mt-8">
				<button
					class="
						h-12
						button-p
						text-center
						mr-3
						px-6
						whitespace-nowrap
					"
					type="button"
					@click="createSubmit"
				>
					Thêm mục tiêu
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import Multiselect from '@suadelabs/vue3-multiselect';
import { $api } from '@/services';
import { useStore } from 'vuex';
import { toEnglish } from '@/helper/toEnglish';

export default defineComponent({
	name: 'CreateGoalsFuture',
	components: {
		Multiselect,
	},
	setup(_, { emit }) {
		const route = useRoute();
		const store = useStore();
		const goals = ref([]);
		const skills = ref([]);
		const isShow = ref(true);
		let error = ref('');
		let assistance_levels = ref([]);

		let goalContentInput = ref('');
		let processInput = ref('');
		let reasonInput = ref('');
		let strategyInput = ref('');
		const skillSelected = ref(null);
		const assistanceSelecteds = ref(['']);

		const enable_goal_assistance_level = ref(false);
		const enable_goal_process = ref(false);
		const enable_goal_reason = ref(false);
		const enable_goal_strategy = ref(false);
		const alertShow = ref(true);

		const org = computed(() => store.state.profile.current_org);

		if (org.value) {
			enable_goal_assistance_level.value =
				org.value.enable_goal_assistance_level;
			enable_goal_process.value = org.value.enable_goal_process;
			enable_goal_reason.value = org.value.enable_goal_reason;
			enable_goal_strategy.value = org.value.enable_goal_strategy;
		}

		const activities = ref([]);

		const errorActivities = ref([]);
		const errorAssistanceLevels = ref([]);

		function addActivity() {
			const length = activities.value.length;
			if (
				activities.value.length &&
				activities.value[length - 1].name?.length <= 0
			) {
				errorActivities.value[length - 1] =
					'Tên hoạt động không được trống';

				setTimeout(() => {
					errorActivities.value[length - 1] = '';
				}, 2000);
			} else {
				activities.value.push({
					name: '',
				});
				errorActivities.value.push('');
			}
		}

		const isAdmin = computed(() => store.getters.isAdmin);

		function removeActivity(index) {
			activities.value.splice(index, 1);
		}

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		async function fetchSkill() {
			try {
				const res = await $api.goal.allSkills();
				if (res?.data) {
					skills.value = res?.data?.data;
					skillSelected.value =
						skillSelected.value || res?.data?.data[0];
				}
			} catch (e) {
				console.log(e);
			}
		}

		function clearInput() {
			goalContentInput.value = '';
			processInput.value = '';
			reasonInput.value = '';
			strategyInput.value = '';
			assistanceSelecteds.value = [''];
			activities.value = [];
			errorActivities.value = [''];
			errorAssistanceLevels.value = [];
		}

		const errorName = ref('');
		const errorStrategy = ref('');
		const errorProcess = ref('');
		const errorReason = ref('');

		async function createSubmit() {
			let error = false;
			assistanceSelecteds.value.some((activity, index) => {
				if (activity.name?.length <= 0 && index !== 0) {
					error = true;
					errorAssistanceLevels.value[index] =
						'Mức độ hỗ trợ không được trống';
				}
			});
			if (
				assistanceSelecteds.value.length === 1 &&
				assistanceSelecteds.value[0].name?.length <= 0
			) {
				assistanceSelecteds.value = [];
			}
			activities.value.some((activity, index) => {
				if (activity.name?.length <= 0 && index !== 0) {
					error = true;
					errorActivities.value[index] =
						'Tên hoạt động không được trống';
				}
			});
			if (
				activities.value.length === 1 &&
				activities.value[0].name?.length <= 0
			) {
				activities.value = [];
			}
			if (error) {
				return;
			}
			try {
				const res = await $api.goal.createGoal(id.value, {
					goal: {
						// @ts-ignore
						assistance_level: assistanceSelecteds.value
							?.map((i) => i.order)
							.filter((i) => !!i),
						assistance_levels: assistanceSelecteds.value
							?.map((i) => i.order)
							.filter((i) => !!i),
						name: goalContentInput.value.replace(/\n/g, '<br />'),
						// @ts-ignore
						skill_id: skillSelected.value?.id || '',
						process: processInput.value,
						reason: reasonInput.value,
						strategy: strategyInput.value,
						activities: activities.value,
					},
				});
				if (res?.data) {
					await fetchSkill();
					await store.dispatch('setAlertMessage', {
						message: 'Tạo mục tiêu thành công',
					});
					clearInput();
					emit('updated');
				}
			} catch (e) {
				console.log(
					'e',
					e.response?.data?.errors_by_field['assistance_level']
				);
				if (e?.response?.data?.errors_by_field['name']) {
					errorName.value =
						'Nội dung ' +
						e?.response?.data?.errors_by_field['name'].join(' ');
				}

				if (e?.response?.data?.errors_by_field['process']) {
					errorProcess.value =
						'Quy trình thực hiện ' +
						e?.response?.data?.errors_by_field['process'].join(' ');
				}

				if (e?.response?.data?.errors_by_field['strategy']) {
					errorStrategy.value =
						'Chiến lược ' +
						e?.response?.data?.errors_by_field['strategy'].join(
							' '
						);
				}

				if (e?.response?.data?.errors_by_field['assistance_level']) {
					console.log(
						'errorAssistanceLevels.value',
						errorAssistanceLevels.value
					);
					errorAssistanceLevels.value.push(
						'Mức độ hỗ trợ ' +
							e?.response?.data?.errors_by_field[
								'assistance_level'
							].join(' ')
					);
					console.log(
						'errorAssistanceLevels.value',
						errorAssistanceLevels.value
					);
				}

				if (e?.response?.data?.errors_by_field['reason']) {
					errorReason.value =
						'cơ sở hợp lý ' +
						e?.response?.data?.errors_by_field['reason'].join(' ');
				}
			} finally {
				setTimeout(() => {
					error = false;
					errorStrategy.value = '';
					errorProcess.value = '';
					errorName.value = '';
					errorReason.value = '';
					errorAssistanceLevels.value = [];
				}, 3000);
			}
		}

		function addAssistanceLevel() {
			assistanceSelecteds.value.push('');
		}

		async function fetchAssistanceLevel() {
		try {
			const res = await $api.org.staffFetchAssistanceLevel();
			if (res?.data) {
				assistance_levels.value = res?.data?.data;
			}
		} catch (e) {
			console.log(e);
		}
	}

		fetchSkill();
		fetchAssistanceLevel();

		return {
			isShow,
			error,
			errorName,
			errorStrategy,
			errorProcess,
			errorReason,
			errorAssistanceLevels,
			assistance_levels,
			skills,
			goals,
			assistanceSelecteds,
			skillSelected,
			goalContentInput,
			processInput,
			reasonInput,
			strategyInput,
			createSubmit,
			enable_goal_assistance_level,
			enable_goal_reason,
			enable_goal_process,
			enable_goal_strategy,
			activities,
			errorActivities,
			addActivity,
			removeActivity,
			alertShow,
			toEnglish,
			addAssistanceLevel,
		};
	},
});
</script>

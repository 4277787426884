<template>
	<div>
		<div :class="modalIsOpen ? 'side-over--active' : ''">
			<div
				class="
					fixed
					h-screen
					w-screen
					top-0
					left-0
					bg-black
					opacity-20
					z-30
				"
				v-if="modalIsOpen"
				@click="modalIsOpen = false"
			></div>
			<CreateGoalModal
				:show="modalIsOpen"
				v-model:skills="skills"
				v-model:data="goalPayloadData"
				v-model:error="error"
				v-model:tab-active="tabActive"
				@submit="() => {}"
				@close="closeModal"
				@updated="updated"
			/>
		</div>
		<div class="w-full">
			<div class="mb-10" v-if="alertShow">
				<div
					class="
						bg-yellow-50
						border-l-4 border-yellow-600
						p-4
						rounded-md
					"
				>
					<div class="relative">
						<div>
							<img
								src="@/assets/images/logo/logo.svg"
								alt="luca-logo"
								height="24"
								width="35"
							/>
						</div>
						<div class="mt-3 text-neutral-600 text-sm">
							Bạn hãy thêm mục tiêu từ Thư viện nội bộ vào Ngân
							hàng mục tiêu.
						</div>
						<div
							class="top-1 right-2 absolute cursor-pointer"
							@click="alertShow = false"
						>
							<svg
								width="12"
								height="12"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
									fill="#E5A42B"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div>
					<div class="__report-lesson pb-30">
						<div
							class="
								__breadcrumbs
								text-sm text-neutral-500
								font-semibold
								flex-nowrap flex
								items-center
								justify-between
							"
						>
							<div class="flex items-center">
								<router-link
									:to="{
										name: 'create_goal_by_lib',
									}"
								>
									Thư Viện Nội Bộ
								</router-link>
								<div class="__breadcrumbs__decorate">
									<DaIcon
										name="das--chevron-down"
										class="
											transform
											-rotate-90
											h-3
											w-3
											mx-2
										"
									/>
								</div>
								<div>{{ goal.name }}</div>
							</div>
						</div>
						<div
							class="
								mt-6
								__report-lesson__header
								flex flex-nowrap
								justify-between
								items-center
							"
						>
							<div>
								<div
									class="
										__record__title
										text-neutral-500
										font-semibold
										whitespace-nowrap
										flex flex-nowrap
										items-start
										text-4xl
										gap-4
									"
								>
									<div>
										<div class="flex items-center gap-6">
											<svg
												width="28"
												height="28"
												viewBox="0 0 28 28"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												class="mb-1"
											>
												<path
													d="M25.4998 5.98259H11.021C10.7609 5.98259 10.5219 5.8514 10.3931 5.63913L9.104 3.51831C8.65802 2.78525 7.83103 2.33337 6.93503 2.33337H2.5C1.11897 2.33337 0 3.38904 0 4.69193V22.1415C0 23.4444 1.11897 24.5 2.5 24.5H25.5C26.881 24.5 28 23.4444 28 22.1415V8.34109C28 7.03906 26.881 5.98253 25.5 5.98253L25.4998 5.98259ZM4.09697 16.1387H6.65903C7.15613 16.1387 7.55903 16.5188 7.55903 16.9878C7.55903 17.4567 7.15613 17.8368 6.65903 17.8368H4.09697C3.59987 17.8368 3.19697 17.4567 3.19697 16.9878C3.19697 16.5188 3.59987 16.1387 4.09697 16.1387ZM10.437 21.1483H4.09697C3.59987 21.1483 3.19697 20.7682 3.19697 20.2992C3.19697 19.8303 3.59987 19.4501 4.09697 19.4501H10.4381C10.9352 19.4501 11.3381 19.8303 11.3381 20.2992C11.3379 20.768 10.935 21.1483 10.437 21.1483Z"
													fill="#94A3B8"
												/>
											</svg>
											<span>{{ goal.name }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mt-2">
							<div v-if="!isDataLoading">
								<div v-if="folders.length">
									<div
										v-for="folder in folders"
										:key="folder.id"
									>
										<GoalFolderItem
											:is-edit="false"
											:folder="folder"
											:skills="skills"
											:selected-goal="selected"
											@action-clicked="doGoal"
											:isInCreateGoal="true"
										>
											<template v-slot:actions>
												<div></div>
											</template>
										</GoalFolderItem>
									</div>
								</div>
								<div
									v-else
									class="
										w-full
										h-full
										flex
										items-center
										justify-center
										flex-col
									"
								>
									<img
										src="@/assets/images/illustrations/not_found.png"
										alt=""
										height="250"
										width="250"
									/>
									<div
										class="
											text-neutral-400 text-lg text-center
										"
										style="max-width: 900px"
									>
										Chưa có thư mục hay mục tiêu nào trong
										thư viện này.
										<br /><br />
										Nếu ngân hàng của bạn gồm nhiều mục tiêu
										được chia theo lĩnh vực hoặc chia theo
										độ tuổi hoặc theo vấn đề của trẻ thì bạn
										nên tạo Thư mục trước khi tạo Mục tiêu.
									</div>
								</div>
							</div>
							<div v-else class="fixed top-1/2 left-1/2">
								<Loading />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DaIcon from '@/components/elements/daIcon/DaIcon';
import GoalFolderItem from '@/views/goalFolders/features/GoalFolderItem';
import Loading from '@/components/common/Loading';
import { $api } from '@/services';
import CreateGoalModal from '@/views/createGoalByLib/CreateGoalModal';

export default {
	name: 'CreateGoalByLibGoals',
	components: {
		CreateGoalModal,
		Loading,
		GoalFolderItem,
		DaIcon,
	},
	data() {
		return {
			alertShow: true,
			isLoading: true,
			goal: {
				id: '',
				name: '',
				created_by_staff: {
					name: '',
				},
				last_updated_at: null,
			},
			inProgress: false,
			skills: [],
			isDataLoading: true,
			modalIsOpen: false,
			goalPayloadData: null,
			error: '',
			tabActive: 0,
			folders: [],
			selected: [],
		};
	},

	created() {
		this.fetchGoalLib();
		this.fetchSkill();
		this.fetchGoalFolders();
	},

	computed: {
		goalId() {
			return this.$route?.params?.goalId || '';
		},

		isAdmin() {
			return this.$store.getters.isAdmin;
		},

		studentId() {
			return this.$route?.params?.id || '';
		},
	},

	methods: {
		updated() {
			this.selected.push(this.goalPayloadData?.id);
			this.fetchGoalFolders();
			this.$emit('updated');
			this.closeModal()
		},

		async fetchSkill() {
			try {
				const {
					data: { data },
				} = await $api.goal.allSkills(this.isAdmin);
				if (data) {
					this.skills = data;
				}
			} catch (e) {
				console.log(e);
			}
		},

		async fetchGoalFolders() {
			try {
				const {
					data: { data },
				} = await $api.goal.fetchAllGoalFolders(
					this.goalId,
					{ student_id: this.studentId },
					this.isAdmin
				);
				this.folders = data;
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isDataLoading = false;
			}
		},

		async fetchGoalLib() {
			try {
				const {
					data: { data },
				} = await $api.goal.fetchGoalLibDetail(
					this.goalId,
					this.isAdmin
				);
				this.goal = data;
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async doGoal(goal) {
			this.modalIsOpen = true;
			this.goalPayloadData = {
				...goal,
				library_item_id: goal?.id,
				id: goal?.goal_id,
				name: goal?.name || goal?.goal_name,
			};
		},

		closeModal() {
			this.modalIsOpen = false;
			this.goalPayloadData = null;
			this.error = '';
			this.tabActive = 0;
		},
	},
};
</script>
